import React from 'react'

const Feedback = () => {
  return (
    <div>
            <h1 className='module__heading'>Feedback Page</h1>

    </div>
  )
}

export default Feedback;
