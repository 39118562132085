import React, { useState } from "react";
import "./table.css";
import DataTable from "react-data-table-component";
import { MdOutlineSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import FilterOptions from "./FilterOptions";
import { loadingSkeleton } from "../../utils";
import { Paginator } from "primereact/paginator";

const customStyles = {
  rows: {
    style: {
      minHeight: "50px", // override the row height
      cursor: "pointer",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      backgroundColor: "var(--primary-color)",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "5px", // override the cell padding for data cells
      paddingRight: "5px",
    },
  },
};


const Table = ({
  columns,
  data,
  // filter,
  // setFilter,
  // setFilteredTags,
  // setFilteredSessions,
  tableRowItem = null,
  popupContent = null,
  hideSearch = false,
  filterOptions = [],
  selectableRows = true,
  onFilter,
  isLoading,
  pagination,
  onPaginationChange,
  handleSelectedRowsChanged = () => {}
}) => {
  const [paginationFirst, setPaginationFirst] = useState(0)

  const navigate = useNavigate();
  // const [selectedRows, setSelectedRows] = useState([]); // Add this line

  const handleRowClick = (row) => {
    if (tableRowItem)
      navigate(`/${tableRowItem}/${row.id}`);
  };
 
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (e) => {
    const value = e.target.value;

    const filteredItems = data.filter((item) => {
      return columns.some((column) => {
        const field = item[column.id];
        if (field === null || field === undefined) {
          return false;
        }
        return field.toString().toLowerCase().includes(value.toLowerCase());
      });
    });

    setFilteredData(filteredItems);

    setSearchText(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPaginationFirst(newPage.first)

    if (onPaginationChange !== undefined)
      onPaginationChange({ page: newPage.page+1, limit: newPage.rows})
  }

  const contextActions = React.useMemo(() => {
		const handleDelete = () => {
		};

		return (
			<Button key="delete" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon>
				Delete
			</Button>
		);
	}, []);


  return (
    <div>
      <div
        style={{
          overflow: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="search-container">
          {!hideSearch ?
          <>
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearch}
            />
            <span className="search-icon">
              <MdOutlineSearch />
            </span>
          </>
          : ''}
        </div>
        <div style={{ alignSelf: "center"}}>
          {popupContent ? popupContent : null}
        </div>
      </div>

      <div>
        {
          filterOptions !== null && filterOptions.length > 0 ?
          <FilterOptions filterOptions={filterOptions} onFilter={onFilter} isLoading={isLoading} /> : null
        }
      </div>
      <DataTable
      
        progressPending={isLoading}
        progressComponent={loadingSkeleton}
        data={searchText.length > 0 ? filteredData : data}
        columns={columns}
        onRowClicked={handleRowClick}
        highlightOnHover
        contextActions={contextActions}
        selectableRows={selectableRows} // Enable selection
        onSelectedRowsChange={handleSelectedRowsChanged}
        customStyles={customStyles}
      >

      </DataTable>

      {pagination ?  
        <Paginator
          totalRecords={pagination.total}
          first={paginationFirst}
          pageLinkSize={pagination.pages && pagination.pages < 5 ? pagination.pages : 5 }
          rowsPerPageOptions={[50, 100, 200]}
          onPageChange={handlePageChange}
          rows={pagination.limit}  
        ></Paginator> : null
      } 
    </div>
  );
};

export default Table;
