import React from "react";
import AssessmentsContainer from "../features/Assessments/AssessmentsContainer";

const Assessments = () => {
  return (
    <div>
      <AssessmentsContainer />
    </div>
  );
};

export default Assessments;
