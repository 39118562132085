export const RELOAD = "RELOAD"
export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER"
export const SET_TOAST = "SET_TOAST"

export const SET_TEMPLATES = "SET_TEMPLATES"
export const SET_ACTIVE_TEMPLATE = "SET_ACTIVE_TEMPLATE"
export const SHOW_ADD_STUDENTS_POPUP = "SHOW_ADD_STUDENTS_POPUP"
export const SHOW_ADD_STUDENTS_TO_TAG_POPUP = "SHOW_ADD_STUDENTS_TO_TAG_POPUP"
export const SHOW_ERRORED_STUDENTS_POPUP = "SHOW_ERRORED_STUDENTS_POPUP"
export const HIDE_ERRORED_STUDENTS_POPUP = "HIDE_ERRORED_STUDENTS_POPUP"
export const SET_SELECTED_STUDENTS = "SET_SELECTED_STUDENTS"
export const SET_STUDENTS = "SET_STUDENTS"
export const SET_STUDENT_FIELDS = "SET_STUDENT_FIELDS"

export const SHOW_ERRORED_RESULTS_POPUP = "SHOW_ERRORED_RESULTS_POPUP"
export const HIDE_ERRORED_RESULTS_POPUP = "HIDE_ERRORED_RESULTS_POPUP"
export const SET_SELECTED_RESULTS = "SET_SELECTED_RESULTS"
export const SET_CURRENT_ASSESSMENT = "SET_CURRENT_ASSESSMENT"
export const SET_CURRENT_ASSESSMENT_RESULTS = "SET_CURRENT_ASSESSMENT_RESULTS"
export const SET_ASSESSMENT_RESULTS = "SET_ASSESSMENT_RESULTS"
export const SET_ASSESSMENT_FILTER_OPTIONS = "SET_ASSESSMENT_FILTER_OPTIONS"

export const SET_ORGANISATION_MEMBERS = "SET_ORGANISATION_MEMBERS"