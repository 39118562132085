import React, {useEffect, useState} from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { StudentsService } from "../../../services/students.service";
import { SelectButton } from "primereact/selectbutton";
import { TabView, TabPanel } from "primereact/tabview";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";

const EditStudentDetailsForm = ({ formData, setFormData, updateStudentDetails, isLoading }) => {
    const [error, setError] = useState()
    const onChange=(e)=>{
        setError('')
        setFormData({...formData,[e.target.name]:e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
    }

    const handleSaveChanges = async () => {
        setError('')
        studentFields.filter(field => field.isArray).forEach(field => {
            formData[field.columnName] = field.selected            
        })
        setFormData({
            ...formData,
        })
        await updateStudentDetails()
    }

    const [studentFields, setStudentFields] = useState([])
    const [shouldRefetch, setShouldRefetch] = useState(true)
    
    useEffect(() => {
        async function fetchStudentFields() {
            const { data: studentFieldsRes} = await StudentsService.getStudentFields();
            setStudentFields(studentFieldsRes.map(studentField => {
                studentField.selected = studentField.isArray === true && formData[studentField.columnName] ? formData[studentField.columnName] : null;
                if (studentField.isArray) {
                    setFormData({ ...formData, [studentField.columnName]: ''})
                }
                return studentField;
            }))
            setShouldRefetch(false)
        }
  
        if (shouldRefetch) {
            fetchStudentFields()
        }
    }, [setStudentFields, formData, setFormData, shouldRefetch])

    const handleEnterKey = async (event, studentField) => {
        const field = studentField.columnName;
        if (event.key === "Enter" && studentField.isArray) {
            setStudentFields(
                studentFields.map(studentField => {
                    if (studentField.columnName === field) {
                        if (Array.isArray(studentField.selected)) {
                            studentField.selected = [...new Set([...studentField.selected, formData[field]])]
                        } else {
                            studentField.selected = [...new Set([studentField.selected!=='' ? studentField.selected: null, formData[field]])].filter(item => item)
                        }
                        console.log('inHandle', studentField.selected)
                    }
                    return studentField;
                })
            )

            setFormData({ ...formData, [field]: ''})
        }
    }

    const removeItem = async (removedItem, fieldColumnName) => {
        const studentField = studentFields.filter(studentField => studentField.columnName === fieldColumnName)[0] ?? null;
        const field = studentField.columnName;
        if (studentField) {
            const remainingItems = studentField.selected.filter(item => item !== removedItem)

            setStudentFields(
                studentFields.map(studentField => {
                    if (studentField.columnName === fieldColumnName) {
                        studentField.selected = remainingItems
                    }
                    return studentField;
                })
            )
            setFormData({ ...formData, [field]: ''})
        }
    }

    const createStudentField = (studentField) => {
        switch(studentField.type) {
            case 'radio':
                return <>
                    <div className="card justify-content-center mb-4" key={studentField.id}>
                        <label htmlFor={studentField.columnName} className="block text-900 font-medium mb-20">{studentField.displayName}</label>
                        <SelectButton
                            name={studentField.columnName}
                            value={formData[studentField.columnName]}
                            onChange={onChange}
                            options={studentField.values ? studentField.values : ['Yes', 'No']}
                            required={studentField.isRequired}
                        />
                        {/* <SelectButton
                            key={studentField.id}
                            value={formData[studentField.columnName]} 
                            onChange={onChange}
                            optionLabel={studentField.displayName}
                            options={studentField.values ?? []}
                            required={studentField.isRequired}
                        /> */}
                    </div>
                </>
            
            default: 
                return <div key={studentField.id} className="mb-2">
                    <label htmlFor={studentField.columnName} className="block text-900 font-medium mb-20">{studentField.displayName}</label>
                    {studentField.columnName !== "yearOfBirth" || (studentField.columnName === "yearOfBirth" && studentField.values.length === 0) ? 
                        <InputText
                            key={studentField.id}
                            value={formData[studentField.columnName]}
                            name={studentField.columnName}
                            id={studentField.columnName}
                            type={studentField.type}
                            placeholder={studentField.isArray ? `Enter individual ${studentField.columnName} and press enter` : ''}
                            className="w-full mb-2"
                            onChange={onChange}
                            required={studentField.isRequired}
                            onKeyUp={(e) => handleEnterKey(e, studentField)}
                        /> : null}
                    {studentField.isArray && Array.isArray(studentField.selected) && studentField.selected?.length > 0 ?
                        <div className="flex flex-row">
                            {(studentField.selected).map((item) => <Tag value={item} icon="pi pi-times" className="mr-1" key={item} onClick={() => removeItem(item, studentField.columnName)}></Tag>)}
                        </div>
                    : null}
                    {studentField.columnName === "yearOfBirth" && studentField.values.length > 0 ?
                        <Dropdown
                            name={studentField.columnName}
                            value={formData[studentField.columnName]}
                            onChange={onChange}
                            options={studentField.values}
                            placeholder={`Select a ${studentField.displayName}`}
                            className="w-full mb-3 "
                        /> : null
                    }
                </div>
        }
    } 

    const formLeftSide = studentFields
                            .filter(field => 
                                !field.columnName.toLowerCase().includes('consent') &&
                                !field.columnName.toLowerCase().includes('guardian')
                            )
                            .map(studentField => createStudentField(studentField))

    const formRightSide = studentFields
                            .filter(field => 
                                field.columnName.toLowerCase().includes('consent') || 
                                field.columnName.toLowerCase().includes('guardian')
                            )
                            .map(studentField => createStudentField(studentField))

    return (
        <div className="w-full m-auto m-2">
            <form onSubmit={handleSubmit}>
                <TabView>
                    <TabPanel header="Student" style={{ fontSize: "13px" }}>
                        {formLeftSide}
                    </TabPanel>

                    <TabPanel header="Guardian & Consent" style={{ fontSize: "13px" }}>
                        {formRightSide}
                    </TabPanel>
                </TabView>
            
                <Button
                    label="Save Changes"
                    icon="pi pi-image"
                    type="button"
                    className="custom-button"
                    onClick={() => handleSaveChanges()}
                    loading={isLoading}
                />
    
                {error!=='' ? <div><span className="line-height-3 text-red-500 mb-3">{error}</span></div> : null}
            </form>
        </div>
    );
};

export default EditStudentDetailsForm;
