import React, { useState } from "react";
import { Calendar } from 'primereact/calendar';
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

const FilterOption = ({ filterOption, formData, setFormData }) => {
  const [dates, setDates] = useState(null);
  const setDateConducted = (e) => {
    setDates(e.value)
    if (e.value) {
      const [fromDateConducted, toDateConducted] = [...e.value];
      setFormData({ ...formData, fromDateConducted, toDateConducted })
    }
  }
  // const handleRowSelected = (rows) => {
  //   // Add this function
  // };

  const [currentFilterOptionValues, setCurrentFilterOptionValues] = useState({}) 

  const onChange = (e) => {
    setCurrentFilterOptionValues({
      ...currentFilterOptionValues,
      [e.target.name]: e.target.value
    })

    setTimeout(() => {
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log(formData, e.target.name)
    }, 0)
  }

  const setOption = () => {
    try {
      switch(filterOption.type) {
        case 'date':
          return <>
            <label className="block text-xs" htmlFor={filterOption.id}>{filterOption.displayName}</label>
            <Calendar inputId={filterOption.id} showIcon numberOfMonths={2} value={dates} onChange={(e) => setDateConducted(e)} className="mb-3 p-inputtext-sm" selectionMode={filterOption.isRange ? "range" : "single"} dateFormat="yy/mm/dd" showButtonBar placeholder="StartDate - EndDate" touchUI/> 
          </>
        case 'string':
          return <>
            <label className="block text-xs" htmlFor={filterOption.id}>{filterOption.displayName}</label>
            <InputText inputId={filterOption.id} value={currentFilterOptionValues[filterOption.id]} name={filterOption.id} id={filterOption.id} type="text" className="w-full mb-3 p-inputtext-sm" onInput={onChange} required/>
          </>
  
        case 'number':
          return <>
            <label className="block text-xs" htmlFor={filterOption.id}>{filterOption.displayName}</label>
            <InputText name={filterOption.id} keyfilter="int" id={filterOption.id} type="text" placeholder={filterOption.placeholder} className="w-full mb-3 p-inputtext-sm" onInput={onChange} onBlur={onChange} required/>
          </>

        case 'enum': 
          return <>
              <label className="block text-xs" htmlFor={filterOption.id}>{filterOption.displayName}</label>

              <Dropdown
                name={filterOption.id}
                value={currentFilterOptionValues[filterOption.id]}
                onChange={onChange}
                options={filterOption.enumValues}
                optionLabel="displayName" 
                optionValue="id" 
                placeholder={`Select a ${filterOption.displayName}`}
                className="w-full md:w-14rem mb-3 "
            />

          </>
  
        default: <></>
      } 
    } catch (e) {
      console.error(`Exception when doing that`, e)
    }
  }

  return (
    <>
      <div className="mr-2">
        {setOption()}
      </div>
    </>
  );
};

export default FilterOption;
