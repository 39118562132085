import React from 'react'

const Inbox = () => {
  return (
    <div>
            <h1 className='module__heading'>Inbox Page</h1>

    </div>
  )
}

export default Inbox;
