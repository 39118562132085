import React from "react";
import Studentcontainer from "../features/Students/Studentcontainer";

const Students = () => {
  return (
    <div>
      <Studentcontainer />
    </div>
  );
};

export default Students;
